import React from 'react';

const FinalPoem = ({ finalPoemMetaData, showFinalPoem }) => {
    if (!finalPoemMetaData || finalPoemMetaData.length === 0) {
        return null;
    }
    const listItems = finalPoemMetaData.map((poemLine) => {
        if (poemLine.lineNumber === "-99") {
        return <div key={poemLine.lineNumber}>Lo!</div>;
        }
        const lineNumberActual = poemLine.lineNumber + 1;
        const hoverText = `from ${poemLine.poem.title} by ${poemLine.poem.author} (line ${lineNumberActual} of ${poemLine.poem.lines.length})`;

        return (
        <div title={hoverText} key={poemLine.lineNumber}>
            <a
            className="App-link custom-link"
            href={`https://poetrydb.org/author,title/${poemLine.poem.author};${poemLine.poem.title}`}
            target="_blank"
            rel="noopener noreferrer"
            >
            {poemLine.poem.lines[poemLine.lineNumber]}
            </a>
        </div>
        );
    });

    return (
        <pre className="Final-poem" style={{ display: showFinalPoem ? '' : 'none' }}>
            <p>{listItems}</p>
        </pre>
    );
};

export default FinalPoem;
