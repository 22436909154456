import React, { useState, useEffect } from 'react';
import './App.css';
import { currentTime } from './utils/time';
import useKeyPress from './hooks/useKeyPress';
import $ from 'jquery';
import boulder from './185-1854792_rock-boulder.png';
import FinalPoem from './FinalPoem';

const versionNumber = "v1.2.0"
//const boulder = "https://img.pngio.com/download-rock-boulder-png-image-with-no-background-pngkeycom-boulder-png-998_1010.png";
//const boulder = "https://www.pngkey.com/png/full/185-1854792_rock-boulder.png";
const boulderAltText = "There is scarcely any passion without struggle. -Albert Camus";
const initialWords = getLinePoetryDBDotOrg();//"One clear stanza";// can take more weight than a whole wagon of elaborate prose"; //generate();

window.sessionStorage.setItem("candidateA","");
window.sessionStorage.setItem("candidateB","");
window.sessionStorage.setItem("candidateC","");
window.sessionStorage.setItem("poemPool",getPoemPool());
window.sessionStorage.setItem("finalPoem","");
window.sessionStorage.setItem("percentComplete","");
window.sessionStorage.setItem("finalPoemMetaData","");
window.sessionStorage.setItem("currentLine", "");
window.sessionStorage.setItem("percentAdd", "");

function App() {
  // Suppress the apostrophe hotkey
  useEffect(() => {
    const handleKeyDown = (event) => {
      const activeElement = document.activeElement;

      // Check if the active element is an input or textarea
      if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA')) {
        return;
      }

      if (event.key === "'" || event.key === "/") {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  //state constants - typing
  const [leftPadding, setLeftPadding] = useState(new Array(20).fill(' ').join(' ')); 
  const [outgoingChars, setOutgoingChars] = useState(''); //contains all outgoing chars, only last 20 displayed
  const [currentChar, setCurrentChar] = useState(initialWords.charAt(0)); //the current char
  const [currentCharOptB, setCurrentCharOptB] = useState("");
  const [currentCharOptC, setCurrentCharOptC] = useState("");
  const [incomingChars, setIncomingChars] = useState(initialWords.substr(1)); //contains all incoming chars, only first 20 displayed
  const [incomingCharsOptB, setIncomingCharsOptB] = useState(getBlankString(initialWords.substr(1)));
  const [incomingCharsOptC, setIncomingCharsOptC] = useState(getBlankString(initialWords.substr(1)));

  //state constants - stats
  const [startTime, setStartTime] = useState();
  const [wordCount, setWordCount] = useState(0);
  const [wpm, setWpm] = useState(0); // eslint-disable-line
  const [accuracy, setAccuracy] = useState(0); // eslint-disable-line
  const [typedChars, setTypedChars] = useState('');
  const [percent, setPercent] = useState(0);

  const [showFinalPoem, setShowFinalPoem] = useState(false);

  useKeyPress(key => {
    //console.log(key) //F12 and view console to see typed keys

    //Set the startTime when the user starts typing the first character. 
    //Don’t set it before the app is even fully mounted. Users will not be happy.
    if (!startTime) {
      setStartTime(currentTime());
    }

    //Assign the outgoingChars and incomingChars state to temporary 
    //variables because you need to use them multiple times.
    let updatedOutgoingChars = outgoingChars;
    let updatedIncomingChars = incomingChars;
    let updatedIncomingCharsOptB = incomingCharsOptB;
    let updatedIncomingCharsOptC = incomingCharsOptC;
    let updatedCurrentChar = incomingChars.charAt(0);

    //TODO: check if key === 'Enter' when a selection needs to be made--> add a line break and get new incoming options

    //Check if the user hits the correct keystroke. 
    //Otherwise, no change to the state of the typing lines.
    if ((key === currentChar)
    ||((key === currentCharOptB)&&(currentCharOptB!==" "))
    ||((key === currentCharOptC)&&(currentCharOptC!==" "))) {
      var correctKeyTyped = true
      if (percent < 100){
        updatedIncomingChars = incomingChars.substring(1);
        updatedIncomingCharsOptB = incomingCharsOptB.substring(1);
        updatedIncomingCharsOptC = incomingCharsOptC.substring(1);
        if (key !== currentChar){
          if (key === currentCharOptB){ //option B chosen
            updatedOutgoingChars += currentCharOptB;
            updatedCurrentChar = incomingCharsOptB.charAt(0);
            updatedIncomingChars = updatedIncomingCharsOptB;
            populateFinalPoem(window.candidateB)
            //append previous store to generated poem, place candidate B in store
            updatedIncomingCharsOptB = getBlankString(incomingCharsOptB);
            updatedIncomingCharsOptC = getBlankString(incomingCharsOptB);
            setCurrentCharOptB(" ");
            setCurrentCharOptC(" ");
          } else if (key === currentCharOptC) { //option C chosen
            updatedOutgoingChars += currentCharOptC;
            updatedCurrentChar = incomingCharsOptC.charAt(0);
            updatedIncomingChars = updatedIncomingCharsOptC;
            populateFinalPoem(window.candidateC)
            //append previous store to generated poem, place candidate C in store
            updatedIncomingCharsOptB = getBlankString(incomingCharsOptC);
            updatedIncomingCharsOptC = getBlankString(incomingCharsOptC);
            setCurrentCharOptB(" ");
            setCurrentCharOptC(" ");
          }
        } else { //option A chosen
          updatedOutgoingChars += currentChar;
          if (currentCharOptB!==" "){
            populateFinalPoem(window.candidateA)
            setCurrentCharOptB(" ");
            updatedIncomingCharsOptB=getBlankString(incomingChars);
          } else {
            setCurrentCharOptB(incomingCharsOptB.charAt(0));
          }
          if (currentCharOptC!==" "){
            setCurrentCharOptC(" ");
            updatedIncomingCharsOptC=getBlankString(incomingChars);
          } else {
            setCurrentCharOptC(incomingCharsOptC.charAt(0));
          }
        }

        document.getElementById("currChar").className="Character-current";

        document.getElementById("TextPath").setAttribute("d",document.getElementById("SpiralPath").getAttribute("d"));

        //Reduce the leftPadding by one character. 
        //This condition will be true for the first 20 correct keystrokes.
        if (leftPadding.length > 0) {
          setLeftPadding(leftPadding.substring(1));
        }
        
        //Append the currentChar to outgoingChars.
        setOutgoingChars(updatedOutgoingChars);
        
        //Update the currentChar with the first character of incomingChars.   
        setCurrentChar(updatedCurrentChar);
        
        //Remove the first character from incomingChars. 
        //Check if the incomingChars still has enough words. 
        //If not, replenish upcoming characters.
        if (updatedIncomingChars.split(' ').length < 5
        && (window.percentComplete < 1) 
        && (incomingCharsOptB.trim().length === 0)//Note: there **was** a bug here... we kept appending things if candidate A was too short --> we get around it by checking to see if incoming option B has already been populated
        && (currentCharOptB.trim().length === 0)) {
          updatedIncomingChars += ' ' + getLineFromPoemPool("A");
          updatedIncomingCharsOptB += getLineFromPoemPool("B");
          updatedIncomingCharsOptC += getLineFromPoemPool("C");
        }
        setIncomingChars(updatedIncomingChars);
        setIncomingCharsOptB(updatedIncomingCharsOptB);
        setIncomingCharsOptC(updatedIncomingCharsOptC);
        
        //Recalculate WPM when the user is about to finish the word. 
        //In this case, you check whether the next character to type is a white space.
        if (incomingChars.charAt(0) === ' ') {
          
          //Increase word count.
          setWordCount(wordCount + 1);
          
          //Calculate elapsed duration in a minute. 
          //Although you will get 0.** minute before one minute, WPM will still be valid.
          const durationInMinutes = (currentTime() - startTime) / 60000.0;
          
          //Set WPM. Limit the number of decimal places to two.
          setWpm(((wordCount + 1) / durationInMinutes).toFixed(2));
        }
      }
    }
    else {
      document.getElementById("currChar").className="Character-current-invalid";
    }
    
    //do this check when suffix of outgoing characters matches currentLine
    if (window.currentLine && window.currentLine.length > 0 && updatedOutgoingChars.endsWith(window.currentLine)){
      //update percent
      var newPercent = Math.min((window.percentComplete * 100).toFixed(4), 100);
      setPercent(newPercent);

      if (newPercent===100){
        setShowFinalPoem(true);
        //TODO delete
        console.log(window.finalPoem)
        console.log(window.finalPoemMetaData)
        //alert(window.finalPoem);
      }
    } else if (correctKeyTyped && isEmptyOrSpaces(updatedIncomingCharsOptB.charAt(0)) && isEmptyOrSpaces(updatedIncomingCharsOptC.charAt(0))) {
      var pendingCharWeight = 1/(window.currentLine.length+1)
      var percentPerChar = pendingCharWeight * window.percentAdd * 100
      setPercent(Math.min((percent + percentPerChar).toFixed(4), 99.9999))
    }
    

    //Update typedChars by appending all keys that have been pressed.
    const updatedTypedChars = typedChars + key;
    setTypedChars(updatedTypedChars);
    
    //Set accuracy by dividing the length of outgoingChars with the length of typedChars. 
    //Limit the decimal places to two.
    setAccuracy(
      ((updatedOutgoingChars.length * 100) / updatedTypedChars.length).toFixed(
        2,
      ),
    );
  });

  return (
    <div className="App">
      <div className='version-number' style={{ position: 'absolute', top: 0, left: 0}}>{versionNumber}</div>
      <table style={{width: "100%"}}>
        <tbody>
          <tr>
            <td style={{width: "33%"}}>
              {/* spiral column */}
              <div className="Spiral">
                <span id="textS"></span>
                <svg version="1.1"
                    xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                    x="0px" y="0px" width="363.652px" height="363.954px" viewBox="120 -60 363.652 363.954" enableBackground="new 0 0 363.652 363.954"
                    space="preserve">
                  <defs>
                    <path id="TextPath"/> 
                    <radialGradient x1="0%" y1="0%" x2="0%" y2="0%" id="SvgjsRadialGradient1001">
                      <stop offset="0" style={{stopColor: "#282c34"}}></stop>
                      <stop offset="1" style={{stopColor: "silver"}}></stop>
                    </radialGradient>
                  </defs>
                  <path id="SpiralPath" style={{transform: "rotate(110deg)"}} fill="none" strokeMiterlimit="10" d="M297.248,0.357c87.872,86.015,87.872,225.473,0,311.488
                      c-70.297,68.812-184.272,68.812-254.569,0c-56.238-55.05-56.238-144.302,0-199.352c44.99-44.04,117.934-44.04,162.924,0
                      c35.992,35.232,35.992,92.354,0,127.585c-28.794,28.185-75.478,28.185-104.272,0c-23.035-22.548-23.035-59.106,0-81.655
                      c18.428-18.039,48.306-18.039,66.734,0c14.742,14.431,14.742,37.828,0,52.259c-11.794,11.545-30.916,11.545-42.71,0"/>
                  <text fontSize="25" style={{transform: "rotate(110deg)"}}>
                    <textPath id="Text" alignmentBaseline="hanging" fill="url(&quot;#SvgjsRadialGradient1001&quot;)" href="#TextPath" startOffset="0">
                      {outgoingChars.split("").reverse().join("").substr(20)}
                    </textPath>
                  </text>
                </svg>    
              </div>
            </td>
            <td style={{width: "34%"}}>
              {/* typing and boulder column */}
              <header className="App-header">        
                <img src={boulder} className="Boulder" alt={boulderAltText}/>
                {/* TODO maybe: stop boulder animation when poem is complete-->remove Boulder class */}
                <div className="Character">
                  <span className="Character-out">
                    {(leftPadding + outgoingChars).slice(-20)}
                  </span>
                  <span>
                    <div id="currChar" className="Character-current">{currentChar}</div>
                    <div id="currCharB">{currentCharOptB}</div>
                    <div id="currCharC">{currentCharOptC}</div>
                  </span>
                  <span>
                    <div style={{textAlign: "left"}}>{incomingChars.substr(0, 20)}</div>
                    <div style={{textAlign: "left"}}>{incomingCharsOptB.substr(0, 20)}</div>
                    <div style={{textAlign: "left"}}>{incomingCharsOptC.substr(0, 20)}</div>
                  </span>
                </div>
                <h3>
                  {/* WPM: {wpm} | ACC: {accuracy}% */}
                  {percent}%
                </h3>
                <a
                  className="App-link custom-link"
                  href="http://oneclearstanza.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Again
                  {/* еще раз */}
                </a>
              </header>
            </td>
            <td style={{width: "33%", textAlign: "left"}}>
              {/* final poem column */}
              <FinalPoem finalPoemMetaData={window.finalPoemMetaData} showFinalPoem={showFinalPoem} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

//#region ALL FUNCTIONS

function getBlankString(sourceString){
  let blankString="";
  for(let i=0; i<sourceString.length; i++){
    blankString+=" ";
  }
  return blankString;
}

//called by app -- queries DB each time we need a new line
function getLinePoetryDBDotOrg(){
  var jqXHR = $.ajax({
    type: "GET",
    url: "https://poetrydb.org/random",
    async: false
  }) 
  var poem = jqXHR.responseJSON[0]
  var line = extractFromPoem(poem, "A")
  return cleanLine(line)
}

//called by app to retrieve line from pre-loaded pool
function getLineFromPoemPool(candidateOption){
  var poem = getPoemFromPool()
  var line = extractFromPoem(poem, candidateOption)
  return line
}

//handles tracking metadata about selections and calculates "completeness of the poem"
function populateFinalPoem(metaData){
  var nextLine = "Hark!"
  if (metaData.lineNumber >= 0) nextLine=metaData.poem.lines[metaData.lineNumber]
  var percentAdd = 1/(metaData.poem.lines.length)
  if (!window.finalPoemMetaData){
    window.finalPoem = "" //TODO may not wind up needing this
    window.percentComplete = 0
    window.finalPoemMetaData = []
    window.currentLine = ""
    window.percentAdd = 0
  }
  window.percentComplete = window.percentComplete + percentAdd
  window.percentAdd = percentAdd
  window.finalPoem = window.finalPoem + nextLine + '\n'
  window.finalPoemMetaData.push(metaData)
  window.currentLine = cleanLine(nextLine)
}

//#region helpers for async load of poems
function getPoemFromPool(){
  if (!window.poemPool){
    window.poemPool = []
  }
  if (window.poemPool.length < 9){
    getPoemPoolAsync()
  }
  var poem = window.poemPool.pop()
  return poem
}

//called once on load
function getPoemPool(){
  var jqXHR = $.ajax({
    type: "GET",
    url: "https://poetrydb.org/random/45",
    async: false,
  })
  window.poemPool = jqXHR.responseJSON;
}

//called subsequently as needed
function getPoemPoolAsync(){
  $.ajax({
    type: "GET",
    url: "https://poetrydb.org/random/15",
    async: true,
    success: gotPoems
  })
}

//callback for async retrieval
function gotPoems(response){
  window.poemPool = window.poemPool.concat(response)
}
//#endregion helpers for async load of poems

//#region helper/formatting functions
function cleanLine(line){
  //TODO: trim leading and trailing single/double quotes

  // eslint-disable-next-line no-control-regex
  return line.replace(/[\u0000-\u001F\u007F-\u009F]/g, "") // command characters
  .replace("’", "'")
  .replace("`", "'")
  .replace(/[\u2011\u2012\u2013\u2014]/g, "-")             // non-breaking hyphen / figure dash / endash
  .replace(/[\u2014\u2015]/g, "--")                        // emdash / horizontal bar
  .replace(/[\u2022]/g, "*")                               // bullet
  .replace(/[\u2018\u2019]/g, "'")                         // smart single quotes
  .replace(/[\u201C\u201D]/g, '"')                         // smart double quotes
  .replace("_", " ")
  .replace(/([.])([^"'])/g, ". $2")
  .replace(/([,])([^"'])/g, ", $2")
  .replace(/([;])([^"'])/g, "; $2")
  .replace(/([:])([^"'])/g, ": $2")
  //.replace(/([^A-Z"'()])([A-Z])/, "$1 $2")                 // add padding before uppercase letters (except when preceded by certain things)... this was trying to fix something that I think is another issue...
  .replace(/[^\x20-\x7E]/g, "")                            // strip non-printable
  .replace(/\s\s+/g, " ")                                  // remove multiple white-spaces
  .trim();
}

function extractFromPoem(poem, candidateOption){
  //check valid line--if not, get next random line
  if (!window.percentComplete){
    window.percentComplete = 0
  }
  var lineNumber = Math.min(Math.round(window.percentComplete * poem.lines.length), poem.lines.length-1) //line closest to current percent, or the last line, whichever is smallest
  var line = cleanLine(poem.lines[lineNumber])
  var validLine=ValidateCandidateLine(line, candidateOption)

  var trys=0
  while (validLine===false){
    var lineNumberRandom = Math.floor(Math.random() * poem.lines.length) //random line--only needed if selected line is invalid
    if (trys===0){
      lineNumber=Math.min(lineNumber++, poem.lines.length-1) //first try will get next line
    } else if (trys===1){
      lineNumber=Math.max(lineNumber-2, 0) //second try will get previous line
    } else {
      lineNumber=lineNumberRandom //subsequent tries will get random line
    }
    line = cleanLine(poem.lines[lineNumber])

    validLine=ValidateCandidateLine(line, candidateOption)

    trys++
    if (trys>10) {
      validLine=true
      //TODO: may want to check if this conflicts with the starting character of another line...
      lineNumber=-99
      line="Hark!"
    }
  }

  var currentCandidate = {poem, lineNumber}
  
  if (candidateOption==="A"){
    window.candidateA = currentCandidate
  } else if (candidateOption==="B"){
    window.candidateB = currentCandidate
  } else if (candidateOption==="C"){
    window.candidateC = currentCandidate
  }
  
  return line
}

function ValidateCandidateLine(line, candidateOption){
  var validLine=!isEmptyOrSpaces(line)
  //check if this line starts with a letter than matches one of the other current candidates
  switch (candidateOption){
    case "B":
      //check A
      if (ciEquals(line[0], cleanLine(window.candidateA.poem.lines[window.candidateA.lineNumber])[0])) validLine=false
      break
    case "C":
      //check A and B
      if (ciEquals(line[0], cleanLine(window.candidateA.poem.lines[window.candidateA.lineNumber])[0])) validLine=false
      else if (ciEquals(line[0], cleanLine(window.candidateB.poem.lines[window.candidateB.lineNumber])[0])) validLine=false
      break
    default:
      break
  }
  return validLine
}

function isEmptyOrSpaces(str){
  return str === null || str.match(/^ *$/) !== null;
}

function ciEquals(a, b) {
  return typeof a === 'string' && typeof b === 'string'
      ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
      : a === b;
}
//#endregion helper/formatting functions

//#endregion FUNCTIONS
export default App;
